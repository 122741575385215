<template>
  <div class="indexer" :class="navid == 1 && change_ash == 1 ? 'bgh' : ''">
    <!-- nav-导航跳转 indexall 今日推荐首页全部 -->
    <div class="" style="height: 5.625rem"></div>
    <!-- banner -->
    <div class="banner px-88" v-if="type == 'nav' && navid != 4 && navid != 9">
      <!-- height="700px" -->
      <el-carousel height="18.5rem" loop autoplay :interval="6000">
        <el-carousel-item v-for="(item, index) in banner" :key="index">
          <el-image
            style="width: 100%; height: 100%; border-radius: 1.25rem"
            :src="item.img_pc"
            fit="cover"
            @click="switchBanner(item)"
          ></el-image>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 首页点击全部跳转 -->
    <div class="px-88 pt-20 pb-20" v-if="type == 'indexall'">
      <div class="flex align-end justify-between mb-35">
        <div class="font-22">{{ title }}</div>
      </div>
      <div class="flex flex-wrap">
        <div
          class="my-scroll-item cursorp mb-20"
          v-for="(item, index) in film"
          :key="index"
          @click="gofilmdetails(item)"
        >
          <el-image
            class="rounded3"
            style="width: 14.5625rem; height: 8.1875rem"
            :src="item.img"
            fit="cover"
          ></el-image>
          <!-- <img :src="item.img" class="rounded3" width="14.5625rem" height="8.1875rem" alt="" /> -->
          <div class="mt-5 text-ellipsis w233 font-14 font-weight-bolder">
            {{ item.name }}
          </div>
          <div class="mt-10 text-ellipsis w233 font-12 text-999999">
            {{ item.abstract }}
          </div>
        </div>
      </div>
    </div>
    <!-- 热点/电影 -->
    <div class="px-88" v-if="type == 'nav'">
      <!-- 分类 -->
      <div class="flex align-center mt-10" v-if="navid != 1 && navid != 4 && navid != 9">
        <div
          class="x-5 flex align-center justify-center font-20 text-666666 cursorp"
          v-for="(item, index) in classList"
          :key="index"
          @click="goallMovie('/allMovie', item.id)"
        >
          <el-image
            class="mr-10"
            style="width: 2.5rem; height: 2.1875rem"
            :src="item.img"
            fit="cover"
          ></el-image>
          <!-- <img :src="item.img" alt="" class="mr-10" width="2.5rem" height="2.1875rem" /> -->
          {{ item.title }}
        </div>
      </div>

      <!-- 抽奖 -->
      <!-- <div class="lottery" v-if="is_open == 1 && navid != 4 && navid != 9">
        <img src="@/assets/img/Lottery.png" width="100%" height="auto" alt="" />
        <div class="lottery_box" v-if="prize && prize.length">
          <Scrollinform :informList="prize"></Scrollinform>
        </div>
        <div class="lottery_btn">
          <div
            class="lottery_btn_one"
            @click="cutnav('/indexer', 'indexall', 2, '有奖促销频道')"
          >
            有奖促销频道
          </div>
          <div class="lottery_btn_two" @click="dialogVisible2 = true">抽奖区</div>
        </div>
      </div> -->
      <div
        class="flex align-end justify-between mb-10"
        v-if="navid != 1 && navid != 4 && navid != 9"
      >
        <div class="font-22">{{ title }}</div>
      </div>
      <!-- 电影内容 -->
      <div class="flex flex-wrap mt-10" v-if="navid != 1">
        <!-- mr-50 -->
        <div
          v-for="(item, index) in film"
          :key="index"
          class="mb-20 cursorp film_li"
          style="width: 16.66%; box-sizing: border-box"
          @click="gofilmdetails(item)"
        >
          <!-- width: 12.3125rem; -->
          <el-image
            class="rounded3"
            style="height: 22.5rem; width: 100%"
            :src="item.img_shu"
            fit="cover"
          ></el-image>
          <!-- w197 -->
          <!-- <img :src="item.img_shu" width="12.3125rem" height="16.9375rem" class="rounded3" alt="" /> -->
          <div class="text-ellipsis font-14 mt-5 font-weight-bolder">
            {{ item.name }}
          </div>
          <div class="text-ellipsis font-12 text-999999 mt-10">
            {{ item.abstract }}
          </div>
        </div>
      </div>
      <!-- 猜你在追 -->
      <div
        class="pb-20 border-bottom mt-10"
        v-if="navid == 1 && chasefilm && chasefilm.length"
      >
        <!-- 标题 -->
        <div
          class="flex align-end justify-between mb-20"
          @click="cutnav('/indexer', 'indexall', 0, '猜你在追')"
        >
          <div class="font-22">猜你在追</div>
          <div class="font-14 text-666666 cursorp">
            查看全部 <i class="el-icon-arrow-right"></i>
          </div>
        </div>
        <!-- 内容 -->
        <div>
          <BetterScroll @end="loadData" v-loading="loading">
            <div
              class="my-scroll-item3"
              v-for="(item, index) in chasefilm"
              :key="index"
              @click="gofilmdetails(item)"
            >
              <el-image
                class="rounded3"
                style="width: 100%; height: 10.625rem;flex-shrink: 0;"
                :src="item.img"
                fit="cover"
              ></el-image>
              <!-- <img :src="item.img" class="rounded3" width="14.5625rem" height="8.1875rem" alt="" /> -->
              <div class="mt-5 text-ellipsis font-14 font-weight-bolder">
                {{ item.name }}
              </div>
              <div class="mt-10 text-ellipsis font-12 text-999999">
                {{ item.abstract }}
              </div>
            </div>
            <!-- <div
              class="my-scroll-item3"
              @click="cutnav('/indexer', 'indexall', 0, '猜你在追')"
            >
              <div class="cnzzmore">查看更多</div>
            </div> -->
          </BetterScroll>
        </div>
      </div>
      <!-- 今日推荐 -->
      <div class="border-bottom" v-if="navid == 1">
        <!-- 标题 -->
        <div class="flex align-center mb-10">
          <!-- <img
            src="@/assets/img/tuijian.png"
            width="1.375rem"
            height="1.375rem"
            alt=""
          /> -->
          <el-image
            class=""
            style="width: 1.375rem; height: 1.375rem"
            :src="require('@/assets/img/tuijian.png')"
            fit="cover"
          ></el-image>
          <div class="ml-5 font-22">今日推荐</div>
        </div>
        <!-- 内容 -->
        <div class="flex">
          <!-- 排行榜 -->
          <div
            class="ranking mr-20 pt-35 pb-10 bg-light-BFBFBF flex flex-wrap box-border"
          >
            <div
              v-for="(item, index) in ranking"
              class="flex align-center mb-25 cursorp x-2 px-20 box-border"
              :key="index"
              @click="gofilmdetails(item)"
            >
              <div
                class="font-26 font-weight-bolder mr-25"
                :class="index < 3 ? 'text-EA6059' : 'text-999999'"
              >
                {{ item.ranking }}
              </div>
              <div class="x-1">
                <!-- 116px 65px -->
                <el-image
                  class="rounded3"
                  style="width: 100%; height: 5rem"
                  :src="item.img"
                  fit="cover"
                ></el-image>
                <!-- <img
                  :src="item.img"
                  class="rounded3"
                  width="7.25rem"
                  height="4.0625rem"
                  alt=""
                /> -->
                <div
                  class="font-14 mt-5 w116 text-ellipsis"
                  :class="index == 0 ? 'text-E95951' : ''"
                >
                  {{ item.name }}
                </div>
              </div>
            </div>
          </div>
          <!-- 推荐内容 -->
          <div class="flex flex-wrap x-1">
            <div
              v-for="(item, index) in todayrecommend"
              :key="index"
              class="mb-20 cursorp film_li2"
              style="width: 33.33%; box-sizing: border-box"
              @click="gofilmdetails(item)"
            >
              <el-image
                class="rounded3"
                style="width: 100%; height: 12.5rem"
                :src="item.img"
                fit="cover"
              ></el-image>
              <!-- <img :src="item.img" alt="" width="14.5625rem" height="8.1875rem" class="rounded3" /> -->
              <div class="w233 font-14 mt-5 text-ellipsis">
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 热播精选 -->
      <!-- <div class="pt-30 pb-40 border-bottom" v-if="navid == 1">
        <div
          class="flex align-end justify-between mb-30"
          @click="cutnav('/indexer', 'indexall', 2, '热播精选')"
        >
          <div class="font-22">热播精选</div>
          <div class="font-14 text-666666 cursorp">
            查看全部 <i class="el-icon-arrow-right"></i>
          </div>
        </div>
        <div class="flex">
          <div class="mr-25 cursorp" @click="gofilmdetails(hot)">
            <img :src="hot.img" width="30.0625rem" height="350" class="rounded3" alt="" />
            <div class="text-ellipsis font-14 w481 mt-20 font-weight-bolder">
              {{ hot.name }}
            </div>
            <div class="text-ellipsis font-12 w481 text-999999 mt-10">
              {{ hot.abstract }}
            </div>
          </div>
          <div class="flex flex-wrap">
            <div
              v-for="(item, index) in hotList"
              :key="index"
              class="mr-10 mb-20 cursorp"
              @click="gofilmdetails(item)"
            >
              <el-image
                class="rounded3"
                style="width: 14.5625rem; height: 8.1875rem"
                :src="item.img"
                fit="cover"
              ></el-image>
              <div class="text-ellipsis font-14 w233 mt-20 font-weight-bolder">
                {{ item.name }}
              </div>
              <div class="text-ellipsis font-12 w233 text-999999 mt-10">
                {{ item.abstract }}
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <!-- 红色经典/点击排行榜 -->
      <div class="flex pt-20 justify-between border-bottom" v-if="navid == 1">
        <!-- 红色经典 -->
        <div style="width: 100%">
          <!-- 标题 -->
          <div
            class="flex align-end justify-between mb-10"
            @click="cutnav('/indexer', 'indexall', 3, '红色经典')"
          >
            <div class="font-22">红色经典</div>
            <div class="font-14 text-666666 cursorp">
              查看全部 <i class="el-icon-arrow-right"></i>
            </div>
          </div>
          <div class="flex flex-wrap">
            <div
              v-for="(item, index) in Info.red"
              :key="index"
              class="mb-20 cursorp film_li3"
              style="width: 20%; box-sizing: border-box"
              @click="gofilmdetails(item)"
            >
              <el-image
                class="rounded3"
                style="width: 100%; height: 9.375rem"
                :src="item.img_shu"
                fit="cover"
              ></el-image>
              <!-- <img
                :src="item.img_shu"
                width="11.9375rem"
                height="16.4375rem"
                class="rounded3"
                alt=""
              /> -->
              <div class="text-ellipsis font-14 w191 mt-5 font-weight-bolder">
                {{ item.name }}
              </div>
              <div class="text-ellipsis font-12 w191 text-999999 mt-10">
                {{ hot.abstract }}
              </div>
            </div>
          </div>
        </div>
        <!-- 点击排行榜  -->
        <div class="clickranking">
          <div class="font-22 mb-10">点击排行榜</div>
          <div class="px-20 py-10 bg-EEEEEE">
            <div
              class="py-20 border-bottom border-ligit-BFBFBF cursorp"
              v-for="(item, index) in Info.click"
              :key="index"
              @click="gofilmdetails(item)"
            >
              <div v-if="index == 0" class="flex">
                <div
                  class="font-26 mr-25 text-EA6059"
                  :class="index < 3 ? 'text-EA6059' : 'text-999999'"
                >
                  {{ index + 1 }}
                </div>
                <el-image
                  class="rounded3"
                  style="width: 3.8125rem; height: 5.1875rem"
                  :src="item.img_shu"
                  fit="cover"
                ></el-image>
                <!-- <img
                  :src="item.img_shu"
                  class="rounded3"
                  width="3.8125rem"
                  height="5.1875rem"
                  alt=""
                /> -->
                <div class="ml-25">
                  <div class="font-14 text-E95951 text-ellipsis w100">
                    {{ item.name }}
                  </div>
                  <div class="font-12 text-999999 text-ellipsis w100">
                    {{ item.abstract }}
                  </div>
                </div>
              </div>
              <div v-else class="flex">
                <div
                  class="font-26 mr-25"
                  :class="index < 3 ? 'text-EA6059' : 'text-999999'"
                >
                  {{ index + 1 }}
                </div>
                <div>
                  <div class="font-14 text-ellipsis font-weight-bolder w191">
                    {{ item.name }}
                  </div>
                  <div class="font-12 text-999999 text-ellipsis w191">
                    {{ item.abstract }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 其它导航 -->
      <div v-if="navid == 1">
        <div v-for="(item, index) in Info.other_nav" :key="index">
          <div class="pt-20 border-bottom" v-if="item.film && item.film.length">
            <!-- 标题 -->
            <div
              class="flex align-end justify-between mb-10"
              @click="cutnav('/indexer', 'indexall', item.id, item.name)"
            >
              <div class="font-22">{{ item.name }}</div>
              <div class="font-14 text-666666 cursorp">
                查看全部 <i class="el-icon-arrow-right"></i>
              </div>
            </div>
            <!-- 内容 -->
            <div class="flex flex-wrap">
              <div
                class="my-scroll-item2 cursorp mb-20 film_li3"
                v-for="(items, indexs) in item.film"
                :key="indexs"
                @click="gofilmdetails(items)"
              >
                <el-image
                  class="rounded3"
                  style="width: 100%; height: 11.875rem"
                  :src="items.img"
                  fit="cover"
                ></el-image>
                <!-- <img
                  :src="items.img"
                  class="rounded3"
                  width="14.5625rem"
                  height="8.1875rem"
                  alt=""
                /> w233-->
                <div class="mt-5 text-ellipsis font-14 font-weight-bolder">
                  {{ items.name }}
                </div>
                <div class="mt-10 text-ellipsis font-12 text-999999">
                  {{ items.abstract }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="flex align-center justify-center py-20 border-top border-ligit-BFBFBF"
      v-if="navid != 1"
    >
      <!-- :total="1000" -->
      <el-pagination
        background
        layout="prev, pager, next"
        prev-text="上一页"
        next-text="下一页"
        :page-count="page_nums"
        @current-change="currentChange"
        :current-page="page"
      >
      </el-pagination>
    </div>
    <!-- 抽奖 -->
    <div class="lottery2">
      <el-dialog
        :visible.sync="dialogVisible2"
        :show-close="false"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
      >
        <div class="flex">
          <!-- 抽奖 -->
          <div style="display: flex; justify-content: center" class="lottery1">
            <lattice-lottery-cope
              ref="lottery1"
              @onend="onend"
              :list="list"
              @onsubmit="request('lottery1')"
            ></lattice-lottery-cope>
            <!-- <lottery-list
              ref="lottery1"
              @onend="onend"
              :list="list"
              @onsubmit="request('lottery1')"
            ></lottery-list> -->
            <!-- 抽奖说明 -->
            <div class="Drawthat" @click="DrawthatBnt">抽奖说明</div>
            <div class="DrawNum">
              {{ DrawNum }}
            </div>
            <!-- 中奖明细 -->
            <div class="zhongjiangminxi cursorp" @click="dialogVisible4 = true">
              <img src="@/assets/img/zhongjiangminxi2.png" alt="" />
            </div>
          </div>
          <!-- 中奖名单 -->
          <div class="zjorder">
            <div class="flex align-center justify-between px-20 py-10">
              <div></div>
              <div class="font-20 text-CCCCCC cursorp" @click="close">
                <i class="el-icon-close"></i>
              </div>
            </div>
            <div class="flex align-center justify-center">
              <!-- <img
                src="@/assets/img/zjorder.png"
                width="16.1875rem"
                height="2.8125rem"
                alt=""
              /> -->
              <el-image
                style="width: 16.1875rem; height: 2.8125rem"
                :src="require('@/assets/img/zjorder.png')"
                fit="cover"
              ></el-image>
            </div>
            <div class="flex justify-center">
              <div class="lottery_box2" v-if="prize2 && prize2.length">
                <Scrollinform2 :informList="prize2"></Scrollinform2>
              </div>
            </div>
          </div>
        </div>
      </el-dialog>
    </div>
    <!-- 抽奖说明 -->
    <el-dialog :visible.sync="dialogVisible3" width="30%" title="抽奖说明" center>
      <div class="" v-html="Drawthattext"></div>
    </el-dialog>
    <!-- 中奖明细 -->
    <el-dialog :visible.sync="dialogVisible4" width="30%" title="中奖明细" center>
      <ul
        class="infinite-list minxi"
        v-infinite-scroll="load"
        style="overflow: auto"
        :infinite-scroll-immediate="false"
      >
        <li v-for="(item, index) in list2" :key="index" class="infinite-list-item">
          <div class="minxi_date">
            {{ item.date_time }}
          </div>
          <div class="minxi_ul">
            <div
              v-for="(list, indexs) in item.list"
              :key="indexs"
              class="minxi_li flex align-center justify-between"
            >
              <div>
                <div class="mb-15 text-26252D">
                  {{ list.title }}
                </div>
                <div class="font-14 text-5E5D65">
                  {{ list.create_time }}
                </div>
              </div>
              <div class="text-FF6253 font-18">
                <span v-if="list.plus_reduce == 1">+</span><span v-else>-</span>
                {{ list.balance }}
              </div>
            </div>
          </div>
        </li>
      </ul>
    </el-dialog>
  </div>
</template>

<script>
import latticeLotteryCope from "@/components/lattice-lottery-cope/packages/Lottery/src/lottery-list";
import BetterScroll from "@/components/betterScroll";
import req from "../../utils/req";
import Scrollinform from "../../components/Scrollinform.vue";
import Scrollinform2 from "@/components/Scrollinform3.vue";
export default {
  components: {
    BetterScroll,
    Scrollinform,
    Scrollinform2,
    latticeLotteryCope,
  },
  // components: { Scrollinform },
  data() {
    return {
      dialogVisible4: false,
      list2: [],
      chasefilm: [],
      title: "",
      page_nums: 0,
      page: 1,
      loading: false,
      Info: "",
      ranking: [], //今日推荐排行榜
      todayrecommend: [], //今日推荐
      hot: {},
      hotList: [],
      navid: "",
      banner: [],
      prize: [],
      prize2: [],
      type: "",
      is_open: "",
      change_ash: 0,
      classList: [
        {
          id: 1,
          img: require("@/assets/img/index_icon1.png"),
          title: "全部",
        },
        {
          id: 2,
          img: require("@/assets/img/index_icon2.png"),
          title: "热度最高",
        },
        {
          id: 3,
          img: require("@/assets/img/index_icon3.png"),
          title: "最多播放",
        },
        {
          id: 4,
          img: require("@/assets/img/index_icon4.png"),
          title: "最新上线",
        },
        {
          id: 5,
          img: require("@/assets/img/index_icon5.png"),
          title: "最多好评",
        },
      ],
      film: [],
      dialogVisible2: false,
      dialogVisible3: false,
      list: [],
      DrawNum: 0,
      Drawthattext: "",
      page2:1,
    };
  },
  filters: {
    lineBreak: function (data) {
      return data.replace(/<br>/g, "\n");
    },
  },
  mounted() {
    this.getdrawSetList();
    this.getchange_ash();
    this.drawSet();
    let navid = this.$route.query.navid;
    let type = this.$route.query.type;
    let title = this.$route.query.title;
    if (navid && type) {
      this.page = 1;
      this.type = type;
      this.navid = navid;
      if (type == "nav" && navid == 1) {
        this.getdata();
        this.getChase();
      } else if (type == "nav" && navid == 9) {
        this.$router.push({
          path: "/creationList",
          query: { type: "creationList" },
        });
      } else {
        if (this.navid == 0) {
          this.getChase();
        } else {
          this.getdata2();
        }
      }
    }
    let token = localStorage.getItem('token')
    if (token) {
      this.page2 = 1
      this.getmyLog();
    }
    if (title) {
      this.title = title;
    }
  },
  watch: {
    // 被侦听的变量count
    $route: {
      handler() {
        this.getchange_ash();
        let navid = this.$route.query.navid;
        let type = this.$route.query.type;
        let title = this.$route.query.title;
        if (navid && type) {
          this.page = 1;
          this.type = type;
          this.navid = navid;
          if (type == "nav" && navid == 1) {
            this.getdata();
            this.getChase();
          } else if (type == "nav" && navid == 9) {
            this.$router.push({
              path: "/creationList",
              query: { type: "creationList" },
            });
          } else {
            if (this.navid == 0) {
              this.getChase();
            } else {
              this.getdata2();
            }
          }
        }
        if (title) {
          this.title = title;
        }
      },
      deep: true,
    },
  },
  methods: {
    load() {
      this.page2 = this.page2 + 1;
      this.getmyLog();
    },
    getmyLog() {
      let that = this;
      req
        .post("center/myLog", {
          type: 4,
          page: this.page2,
        })
        .then((res) => {
          if (res.code != 200) {
            // that.$message.error(res.msg);
            return;
          }
          if (this.page2 == 1) {
            that.list2 = res.data.data.list;
          } else {
            if (res.data.data && res.data.data.list.length) {
              that.list2 = [...that.list2, ...res.data.data.list];
            } else {
              // that.$message.error("暂无更多数据");
            }
          }
        })
        .catch((err) => {
          console.log(err);
          // that.$message.error(err.msg);
        });
    },
    switchBanner(item) {
      console.log("进入",item);
      if (item.jump_url_pc) {
        let a = item.jump_url_pc;
        // let a = JSON.parse(item.jump_url_pc);
        if (a.type == 1) {
          this.$router.push({
            path: "/creationDetail2",
            query: { type: "creationDetail2", moveid: a.moveid },
          });
        }else if(a.type == 2){
          this.$router.push({
            path: "/filmdetails",
            query: { type: "filmdetails", moveid: a.moveid },
          });
        }
      }
    },
    close() {
      this.dialogVisible2 = false;
      this.$router.go(0);
    },
    request(name) {
      //模拟抽奖请求
      let that = this;
      if (!that.DrawNum || that.DrawNum == 0) {
        that.$message.error("你当天的抽奖次数已用完~");
        return;
      }
      req
        .post("index/doDraw", {})
        .then((res) => {
          if (res.code != 200) {
            // that.$message.error(res.msg);
            return;
          }
          console.log(that.list);
          if (that.list && that.list.length) {
            that.list.forEach((item, index) => {
              if (res.data.id == item.id) {
                this.$refs[name].go(index);
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
          // that.$message.error(err.msg);
        });
      // setTimeout(() => {
      //   let luckyIndex = this.rndNum(0, 7);
      //   console.log(luckyIndex);
      //   this.$refs[name].go(luckyIndex);
      // }, 100);
    },
    getdrawSetList() {
      let that = this;
      req
        .post("index/drawGoods", {})
        .then((res) => {
          if (res.code != 200) {
            // that.$message.error(res.msg);
            return;
          }
          that.list = res.data;
          console.log("list", that.list);
        })
        .catch((err) => {
          console.log(err);
          // that.$message.error(err.msg);
        });
      let token = localStorage.getItem("token");
      if (token) {
        req
          .post("index/surplusNums", {})
          .then((res) => {
            if (res.code != 200) {
              // that.$message.error(res.msg);
              return;
            }
            that.DrawNum = res.data;
          })
          .catch((err) => {
            console.log(err);
            // that.$message.error(err.msg);
          });
      }
    },
    // 抽奖说明
    DrawthatBnt() {
      // this.dialogVisible2 = false;
      this.dialogVisible3 = true;
    },
    //抽奖动画结束
    onend(data) {
      let that = this;
      that.dialogVisible2 = false;
      // if (data.name == "谢谢惠顾") {
      //   alert("很遗憾您没有中奖");
      //   that.page = 1;
      //   that.getdata();
      //   that.getdrawSetList();
      //   that.drawSet();
      //   that.$router.go(0);
      //   return;
      // }
      req
        .post("index/addDraw", {
          id: data.id,
        })
        .then((res) => {
          if (res.code != 200) {
            // that.$message.error(res.msg);
            return;
          }
          that.page = 1;
          that.getdata();
          that.getdrawSetList();
          that.drawSet();
          that.$router.go(0);
          alert("恭喜您获得：" + data.name);
        })
        .catch((err) => {
          console.log(err);
          // that.$message.error(err.msg);
        });
      console.log("抽奖结果回调：", data);
    },
    getchange_ash() {
      let that = this;
      req
        .post("index/setting", {})
        .then((res) => {
          if (res.code != 200) {
            // that.$message.error(res.msg);
            return;
          }
          that.change_ash = res.data.change_ash;
        })
        .catch((err) => {
          console.log(err);
          // that.$message.error(err.msg);
        });
    },
    drawSet() {
      let that = this;
      req
        .post("index/drawSet", {})
        .then((res) => {
          if (res.code != 200) {
            // that.$message.error(res.msg);
            return;
          }
          that.Drawthattext = res.data.prize_content;
          that.is_open = res.data.is_open;
          that.prize2 = res.data.prize;
        })
        .catch((err) => {
          console.log(err);
          // that.$message.error(err.msg);
        });
    },
    // 跳转详情
    gofilmdetails(item) {
      if (item.type == 2) {
        this.$router.push({
          path: "/richtext2",
          query: { type: "richtext2", moveid: item.id },
        });
      } else if (item.type == 3) {
        this.$router.push({
          path: "/creationDetail2",
          query: { type: "creationDetail2", moveid: item.id },
        });
      } else {
        if (this.navid == 4) {
          this.$router.push({
            path: "/richtext2",
            query: { type: "richtext2", moveid: item.id },
          });
        } else {
          this.$router.push({
            path: "/filmdetails",
            query: { type: "filmdetails", moveid: item.id },
          });
        }
      }
    },

    // 首页内跳转全部
    cutnav(path, type, id, name) {
      if (id == 9) {
        this.$router.push({
          path: "/creationList",
          query: { type: "creationList" },
        });
      } else {
        this.$router.push({
          path: path,
          query: { type, navid: id, title: name },
        });
      }
    },
    // 跳转全部
    goallMovie(path, id) {
      console.log(this.navid);
      this.$router.push({
        path: path,
        query: { type: path, classid: id, title: this.title, navid: this.navid },
      });
    },
    loadData() {
      // this.loading = true;
      // if (this.items.length < 15) {
      //   console.log("load start");
      //   setTimeout(() => {
      //     console.log("load end");
      //     for (let i = 0; i < 3; i++) {
      //       this.items.push({
      //         text: `元素${this.items.length}`,
      //       });
      //     }
      //     this.loading = false;
      //   }, 1000);
      // } else {
      //   this.loading = false;
      //   this.loaded();
      // }
    },
    loaded() {
      this.$message({
        message: "没有更多数据了",
        duration: 1500,
      });
    },
    itemClick(item, index) {
      console.log(item, index);
    },
    getChase() {
      let that = this;
      req
        .post("index/chase", {})
        .then((res) => {
          if (res.code != 200) {
            that.$message.error(res.msg);
            return;
          }
          let chasefilm = [];
          if (this.navid == 1) {
            if (res.data && res.data.length) {
              res.data.forEach((item, index) => {
                if (index < 6) {
                  chasefilm.push(item);
                }
              });
            }
            that.chasefilm = chasefilm;
          } else {
            that.film = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
          that.$message.error(err.msg);
        });
    },
    getdata() {
      let that = this;
      req
        .post("index/index", {})
        .then((res) => {
          if (res.code != 200) {
            that.$message.error(res.msg);
            return;
          }
          that.Info = res.data;
          if (res.data.banner && res.data.banner.length) {
            that.banner = res.data.banner;
          }
          if (res.data.prize && res.data.prize.length) {
            that.prize = res.data.prize;
          }
          // ranking-今日推荐排行榜/todayrecommend-今日推荐
          if (res.data.ranking && res.data.ranking.length) {
            let ranking = [];
            let todayrecommend = [];
            res.data.ranking.forEach((item, index) => {
              if (index < 6) {
                ranking.push(item);
              } else {
                todayrecommend.push(item);
              }
            });
            that.ranking = ranking;
            that.todayrecommend = todayrecommend;
          }
          // 热播精选
          if (res.data.hot && res.data.hot.length) {
            let arr = [];
            res.data.hot.forEach((item, index) => {
              if (index != 0) {
                arr.push(item);
              }
            });
            that.hotList = arr;
            that.hot = res.data.hot[0];
          }
          // 偶数
          //  even = res.data.prize.filter((_item, index) => index % 2 === 0);
          // 奇数
          //  odd = res.data.prize.filter((_item, index) => index % 2 !== 0);
        })
        .catch((err) => {
          console.log(err);
          that.$message.error(err.msg);
        });
    },
    getdata2() {
      let that = this;
      if (that.navid == 4) {
        req
          .post("index/comic", {
            page: that.page,
          })
          .then((res) => {
            if (res.code != 200) {
              that.$message.error(res.msg);
              return;
            }
            that.film = res.data.data;
            that.page_nums = res.data.last_page;
          })
          .catch((err) => {
            console.log(err);
            that.$message.error(err.msg);
          });
      } else {
        req
          .post("index/filmNav", {
            page: that.page,
            nav_id: that.navid,
            nums: "12",
          })
          .then((res) => {
            if (res.code != 200) {
              that.$message.error(res.msg);
              return;
            }
            console.log("成功", res.data);
            if (res.data.banner && res.data.banner.length) {
              that.banner = res.data.banner;
            }
            if (res.data.prize && res.data.prize.length) {
              that.prize = res.data.prize;
            }
            that.film = res.data.film.data;

            that.page_nums = res.data.film.last_page;
          })
          .catch((err) => {
            console.log(err);
            that.$message.error(err.msg);
          });
      }
    },
    currentChange(e) {
      console.log(e);
      this.page = e;
      this.getdata2();
    },
  },
};
</script>

<style lang="scss">
p {
  margin: 0;
  padding: 0;
}
.film_li {
  padding-right: 1.875rem;
}
.film_li:nth-child(6n) {
  padding-right: 0;
}
.film_li2 {
  padding-right: 2.5rem;
}
.film_li2:nth-child(3n) {
  padding-right: 0;
}
.film_li3 {
  padding-right: 2.5rem;
}
.film_li3:nth-child(5n) {
  padding-right: 0;
}
.bgh {
  filter: grayscale(1);
}
.lottery2 {
  .el-dialog__header {
    padding: 0;
  }
  .el-dialog__body {
    padding: 0;
  }
  .el-dialog {
    width: fit-content;
  }
  .zjorder {
    width: 34rem;
  }
}
.minxi {
  height: 30rem;
  &_date {
    padding: 1.375rem 1.125rem 1rem;
    background-color: #f2f2f2;
    color: #26252d;
    font-weight: bold;
  }
  &_ul {
    padding: 1.75rem 1.375rem 0.3125rem;
  }
  &_li {
    margin-bottom: 2.5rem;
  }
}
/* 为了防止样式重叠 建议给组件加上自定义的class类名 */
.lottery1 {
  width: 34rem;
  height: 47.75rem;
  background-image: url("../../assets/img/Lotterybg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  .zhongjiangminxi {
    position: absolute;
    top: 0;
    right: 0;
    width: 5.25rem;
    height: 2.75rem;
    img {
      width: 100%;
      height: 100%;
    }
    position: absolute;
    top: 5%;
    right: 0;
  }
  // 抽奖说明
  .Drawthat {
    position: absolute;
    top: 15%;
    right: 0;
    width: 4.875rem;
    height: 1.8125rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.2);
    color: #ffffff;
    border-radius: 0.5625rem 0 0 0.5625rem;
    cursor: pointer;
  }
  //抽奖次数
  .DrawNum {
    position: absolute;
    top: 30%;
    right: 34%;
    font-size: 1.0625rem;
    color: #ffffff;
  }
  /* 奖品列表的蒙层，根据抽奖动画选中的位置被显示出来 */
  .prize__item__mask {
    background-color: rgba(0, 0, 0, 0.3);
  }
  /* 奖品图样式 */
  .prize__item__image {
    width: 3.5625rem;
    height: 3.25rem;
  }
  /* 九宫格组件样式 为了跟随羡慕的自适应方案 */
  .lattice__lottery__box {
    margin: 0 auto;
    margin-top: 16.95rem;
    width: 23.25rem;
  }
  /* 奖品块样式 为了跟随羡慕的自适应方案 */
  .lattice__lottery__item {
    width: 6rem;
    height: 6rem;
    // margin-bottom: 0.4375rem;
    border: 0;
    font-size: 0.75rem;
    color: #ff4b0d;
    span {
      // word-break: normal;
      // width: auto;
      // display: block;
      // white-space: pre-wrap;
      // word-wrap: break-word;
      // overflow: hidden;
      text-align: center;
      font-weight: 300;
      font-style: normal;
      height: auto;
      display: block;
      white-space: pre-wrap;
      word-break: break-all;
      // word-break: break-all;
    }
  }
  /* 抽奖按钮 */
  .lattice__lottery__btn {
    width: 15.1875rem;
    height: 5.1875rem;
    background-image: url("../../assets/img/Lotterybtn.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    border: 0;
    color: transparent;
    /* lottery-list 组件的抽奖按钮 */
    &.list {
      margin-top: 3rem;
    }
  }
  /* 抽奖动画处于选中状态的奖品块样式 */
  ::v-deep .lattice__lottery__actItem {
    background-color: rebeccapurple;
    color: white;
  }
  .el-dialog__title {
    font-size: 1.25rem;
    color: #333333;
  }
}
.lottery_box2 {
  width: 28.4375rem;
}
.indexer {
  .banner {
    .el-carousel__indicators--horizontal {
      bottom: 2% !important;
      left: 10% !important;
    }
    .el-carousel__button {
      // opacity: 1 !important;
      width: 0.5625rem !important;
      height: 0.5625rem !important;
      border-radius: 50%;
    }
    .el-carousel__indicator.is-active button {
      width: 2.5625rem !important;
      height: 0.5625rem !important;
      border-radius: 6.25rem;
    }
  }
  .lottery {
    // margin-top: 2.375rem;
    position: relative;

    &_box {
      position: absolute;
      right: 18%;
      top: 38%;
      width: 40rem;
    }
    &_btn {
      position: absolute;
      bottom: 10%;
      left: 10%;
      display: flex;
      align-items: center;
      &_one {
        background-image: url("../../assets/img/choujiangbtnbg.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        width: 9.375rem;
        height: 2.25rem;
        line-height: 2.25rem;
        color: #f04c32;
        font-size: 1rem;
        text-align: center;
        border-radius: 6.25rem;
        margin-right: 1.25rem;
        cursor: pointer;
      }
      &_two {
        background-image: url("../../assets/img/choujiangbtnbg.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        width: 9.375rem;
        height: 2.25rem;
        line-height: 2.25rem;
        color: #f04c32;
        font-size: 1rem;
        text-align: center;
        border-radius: 6.25rem;
        cursor: pointer;
      }
    }
  }
  .w233 {
    width: 14.5625rem;
  }
  .w116 {
    width: 7.25rem;
  }
  .w100 {
    width: 6.25rem;
  }
  .w191 {
    width: 11.9375rem;
  }
  .w481 {
    width: 30.0625rem;
  }
  .w197 {
    width: 12.3125rem;
  }
  .my-scroll-item {
    margin-right: 1.25rem;
    cursor: pointer;
  }
  .my-scroll-item2 {
    // margin-right: 1.25rem;
    width: 20%;
    // padding: 0 0.625rem;
    box-sizing: border-box;
    cursor: pointer;
  }
  .my-scroll-item3 {
    // margin-right: 1.25rem;
    width: 16.66%;
    padding: 0 0.625rem;
    box-sizing: border-box;
    cursor: pointer;
    flex-shrink: 0;
  }
  .cnzzmore {
    // width: 14.5625rem;
    width: 100%;
    height: 10.625rem;
    padding: 0 0.625rem;
    box-sizing: border-box;
    background: #f1f1f1;
    color: #999999;
    text-align: center;
    line-height: 10.625rem;
    cursor: pointer;
  }
  .ranking {
    width: 37.5rem;
    // width: 44.625rem;
    // width: 28.625rem;
    // height: 23rem;
    box-sizing: border-box;
  }
  .clickranking {
    width: 15.875rem;
    box-sizing: border-box;
    margin-left: 1.875rem;
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #e7524c !important;
  }
}
</style>
